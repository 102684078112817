export const Logo = (props:{size?:number})=>{
    return <svg width={props?.size?props?.size:"149"} height={props?.size?("auto"):"40"} viewBox="0 0 149 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3860_34596)">
    <path d="M11.13 26.4177C9.57386 26.4177 8.59279 25.365 8.59279 23.7012V11.3752H14.1409V6.99491H8.59279L8.62662 0H4.39788L3.58597 6.99491H0V11.3752H3.48448V23.837C3.48448 28.1155 6.19087 31.0017 10.1828 31.0017C11.3668 31.0017 12.8554 30.764 13.9379 30.3905L14.4792 30.2207V25.7046L13.4643 26.0102C12.72 26.2818 11.6713 26.4177 11.13 26.4177Z" fill="white"/>
    <path d="M28.9246 6.55348C26.8948 6.55348 24.8988 7.43633 23.5456 8.79457V6.96095H18.5727V30.1528H23.681V17.725C23.681 13.8879 25.5754 11.3073 28.4172 11.3073C29.1276 11.3073 30.3116 11.4431 31.3265 11.8846L32.4091 12.3599V7.43633L32.0031 7.2326C31.1912 6.79117 30.041 6.55348 28.9246 6.55348Z" fill="white"/>
    <path d="M45.8735 6.11203C38.8031 6.11203 33.6609 11.2394 33.6609 18.2682C33.6609 25.6366 38.8707 31.0017 46.0426 31.0017C51.1171 31.0017 55.2782 28.6248 57.1388 24.6519L57.4771 23.9728L53.1807 21.7996L52.8086 22.4788C51.5569 24.8217 49.0197 26.2479 46.1103 26.2479C42.4567 26.2479 39.7165 24.0407 38.9722 20.5433H57.8493L57.9169 19.8302C57.9508 19.3209 57.9508 18.7776 57.9508 18.3361C57.9508 11.2733 52.8763 6.11203 45.8735 6.11203ZM45.7043 10.8998C49.0535 10.8998 51.6246 12.8013 52.5042 15.8574H39.0737C39.8856 12.8013 42.4229 10.8998 45.7043 10.8998Z" fill="white"/>
    <path d="M73.2071 6.11203C66.1367 6.11203 60.9945 11.2394 60.9945 18.2682C60.9945 25.6366 66.2043 31.0017 73.3763 31.0017C78.4507 31.0017 82.6118 28.6248 84.4725 24.6519L84.8108 23.9728L80.5144 21.7996L80.1422 22.4788C78.8905 24.8217 76.3533 26.2479 73.4439 26.2479C69.7903 26.2479 67.0501 24.0407 66.3058 20.5433H85.1829L85.2506 19.8302C85.2844 19.3209 85.2844 18.7776 85.2844 18.3361C85.2844 11.2733 80.2099 6.11203 73.2071 6.11203ZM73.038 10.8998C76.3871 10.8998 78.9582 12.8013 79.8378 15.8574H66.4073C67.2192 12.8013 69.7565 10.8998 73.038 10.8998Z" fill="white"/>
    <path d="M102.031 6.11203C98.952 6.11203 96.3471 7.19862 94.5203 9.20201V6.96093H89.412V40H94.5203V27.9796C96.3471 29.949 98.9858 31.0356 102.064 31.0356C108.83 31.0356 113.939 25.6706 113.939 18.5738C113.972 11.4771 108.83 6.11203 102.031 6.11203ZM108.864 18.5399C108.864 22.9202 105.786 26.2479 101.658 26.2479C97.5311 26.2479 94.4188 22.9541 94.4188 18.5399C94.4188 14.1596 97.5311 10.8998 101.658 10.8998C105.819 10.8998 108.864 14.1256 108.864 18.5399Z" fill="white"/>
    <path d="M133.932 25.3989H146.246V30.1528H123.986L142.592 6.99486H148.749L133.932 25.3989Z" fill="#FEBF43"/>
    <g opacity="0.2">
    <g opacity="0.2">
    <mask id="mask0_3860_34596" maskUnits="userSpaceOnUse" x="130" y="25" width="17" height="6">
    <g opacity="0.2">
    <g opacity="0.2">
    <path opacity="0.2" d="M146.246 25.3989H130.346V30.1867H146.246V25.3989Z" fill="url(#paint0_linear_3860_34596)"/>
    </g>
    </g>
    </mask>
    <g mask="url(#mask0_3860_34596)">
    <path opacity="0.2" d="M146.246 25.3989H130.346V30.1867H146.246V25.3989Z" fill="url(#paint1_linear_3860_34596)"/>
    </g>
    </g>
    </g>
    <path d="M130.008 11.7487H117.694V6.99486H139.954L121.348 30.1528H115.191L130.008 11.7487Z" fill="#FEBF43"/>
    <g opacity="0.2">
    <g opacity="0.2">
    <mask id="mask1_3860_34596"  maskUnits="userSpaceOnUse" x="117" y="6" width="17" height="6">
    <g opacity="0.2">
    <g opacity="0.2">
    <path opacity="0.2" d="M133.594 6.99486H117.694V11.7826H133.594V6.99486Z" fill="url(#paint2_linear_3860_34596)"/>
    </g>
    </g>
    </mask>
    <g mask="url(#mask1_3860_34596)">
    <path opacity="0.2" d="M133.594 6.99486H117.694V11.7826H133.594V6.99486Z" fill="url(#paint3_linear_3860_34596)"/>
    </g>
    </g>
    </g>
    </g>
    <defs>
    <linearGradient id="paint0_linear_3860_34596" x1="130.316" y1="27.7827" x2="146.204" y2="27.7827" gradientUnits="userSpaceOnUse">
    <stop stopColor="white"/>
    <stop offset="1"/>
    </linearGradient>
    <linearGradient id="paint1_linear_3860_34596" x1="130.316" y1="27.7827" x2="146.204" y2="27.7827" gradientUnits="userSpaceOnUse">
    <stop stopColor="#231F20"/>
    <stop offset="1" stopColor="white"/>
    </linearGradient>
    <linearGradient id="paint2_linear_3860_34596" x1="133.631" y1="9.35728" x2="117.735" y2="9.35728" gradientUnits="userSpaceOnUse">
    <stop stopColor="white"/>
    <stop offset="1"/>
    </linearGradient>
    <linearGradient id="paint3_linear_3860_34596" x1="133.631" y1="9.35728" x2="117.735" y2="9.35728" gradientUnits="userSpaceOnUse">
    <stop stopColor="#231F20"/>
    <stop offset="1" stopColor="white"/>
    </linearGradient>
    <clipPath id="clip0_3860_34596">
    <rect width="148.75" height="40" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    
}