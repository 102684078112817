import { useEffect, useState } from "react";
import { PostRequest,GetRequest, ReturnComma } from "../../includes/functions";
import useHttpHook from "../../includes/useHttpHook";
import moment from "moment";
import { BaseLoader } from "../../components/baseloader";
import { BaseButton } from "../../components/buttons";
import SubscriptionModal from "../../components/planSubscriptionModal";
import PaymentModal from "../payment";
import { CONSTANTS } from "../../includes/constant";
import { NavLink } from "react-router-dom";
interface SubscriptionHistoryProp {
amount:string;
memo:string;
id:string;
email:string;
ref:string;
status:string;
createdAt:string;
title:string;
planId:string;
txtRef:string;
txtId:string;
months:string;
}
export interface PlansProp {
id:string;
title:string;
description:string;
price:string;
createdAt:string;
}
const HomeScreen = ()=>{
    const [txtHistory,setTxtHistory] = useState<SubscriptionHistoryProp[]>([]);
    const [subscriptions,setSubscriptions] = useState<SubscriptionHistoryProp[]>([]);
    const [selectedPlans,setSelectedPlans] = useState<PlansProp | null>(null);
    const {handleGetSubscriptions,handleGetTransactions,loading} = useHttpHook();
     const GetHistory = ()=>{
        handleGetTransactions().then((res)=>{
            if(res.status)
            {
              setTxtHistory(res.data.transactions);
            }
        })
    }
   
    const GetSubscription = ()=>{
      handleGetSubscriptions().then((res)=>{
            if(res.status)
            {
              setSubscriptions(res.data.subscriptions);
            }
        })
    }
    useEffect(()=>{
      GetSubscription();
      GetHistory();
    },[])
return <div >
  <div className="planCard-container">
   <div className={`card bg-light m-3`} style={{maxWidth:"18rem",minWidth:"16rem"}}>
  <div className="card-body">
  <div className="fw-bold fs-30">Total Transaction</div>
    <div className="fs-3 mt-3">{txtHistory.length}</div>
  </div>
</div>
<div className={`card bg-light m-3`} style={{maxWidth:"18rem",minWidth:"16rem"}}>
  <div className="card-body">
  <div className="fw-bold fs-30">Total Subscription</div>
    <ul className="fs-13 mt-2"></ul>
    <div className="fs-3 mt-3">{subscriptions.length}</div>
  </div>
</div>
<div className={`m-3`} style={{maxWidth:"18rem",minWidth:"16rem"}}>
  <NavLink 
  to={CONSTANTS.Routes.Subscriptions}
  className={"base-button p-3"}
  >
  Goto subscription
  </NavLink>
</div>
  </div>
  <div className="mx-3 fs-5 mb-2">
    Recent transactions
  </div>
  <div className="card mx-3 mb-3">
  <table className="table">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Description</th>
      <th scope="col">Ref No.</th>
      <th scope="col">Amount</th>
      <th scope="col">Status</th>
      <th scope="col">Date</th>
    </tr>
  </thead>
  <tbody>
{loading &&<tr >
    <td colSpan={6}>
        <BaseLoader  /> loading ...
    </td>
    </tr>}
    {txtHistory.filter((a,i)=>i < 5).map((a,i)=><tr key={i}>
      <th scope="row">{i+1}</th>
      <td>{a.memo}</td>
      <td>{a.ref}</td>
      <td>NGN{ReturnComma(a.amount)}</td>
      <td><div className={a.status} >{a.status}</div></td>
      <td>{moment(a.createdAt).format("Do, MMM YYYY")}</td>
    </tr>)}
  </tbody>
</table>
</div>
<div className="mx-3 fs-5 mb-2">
    Recent Subscription
  </div>
  <div className="card mx-3">
    <table className="table">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Plan Name</th>
      <th scope="col">Plan Description</th>
      <th scope="col">Ref No.</th>
      <th scope="col">Amount</th>
      <th scope="col">Months</th>
      <th scope="col">Date</th>
    </tr>
  </thead>
  <tbody>
{loading &&<tr >
    <td colSpan={6}>
        <BaseLoader  /> loading ...
    </td>
    </tr>}
    {subscriptions.filter((a,i)=>i < 5).map((a,i)=><tr key={i}>
      <th scope="row">{i+1}</th>
      <td>{a.title}</td>
      <td>{a.memo}</td>
      <td>{a.txtRef}</td>
      <td>NGN{ReturnComma(a.amount)}</td>
      <td>{ReturnComma(a.months)}</td>
      <td>{moment(a.createdAt).format("Do, MMM YYYY")}</td>
    </tr>)}
  </tbody>
</table>
</div>
</div>
}
export default HomeScreen;