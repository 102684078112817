import { useEffect, useState } from "react";
import { ReturnComma } from "../../includes/functions";
import { PlansProp } from "../../screens/subscriptions";
import { BaseButton } from "../../components/buttons";
import useHttpHook from "../../includes/useHttpHook";

interface SubscriptionModalProp {
    onClose:()=>void;
    paymentUrl:string;
    
}
const PaymentModal = (props:SubscriptionModalProp)=>{
   const {handleConfirmPayment} = useHttpHook();
    useEffect(()=>{
      window.addEventListener('message', ({data}) => {
        if(data?.event && data.event === "success")
          {
            handleConfirmPayment(data.data.reference,props.paymentUrl).then((res)=>{

            })
            
            props.onClose()
          }else if(data === "close")
          {
            props.onClose()
          }
        
       },false);
    },[])
return <div className="modal"  tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
<div className="modal-dialog modal-lg">
  <div className="modal-content" style={{minHeight:"80vh"}}>
    <div className="modal-header">
      <h5 className="modal-title" id="exampleModalLabel">Payment</h5>
      <button onClick={()=>props.onClose()} type="button" className="btn-close" ></button>
    </div>
    <div className="modal-body">
    <iframe src={props.paymentUrl} 
    style={{border:0,width:"100%",minHeight:"70vh"}}
    ></iframe>
    </div>
    <div className="modal-footer">
      
  </div>
  </div>
</div>
</div>
}
export default PaymentModal;