import { useEffect, useState } from "react";
import { ReturnComma } from "../../includes/functions";
import { PlansProp } from "../../screens/subscriptions";
import { BaseButton } from "../buttons";
import useHttpHook from "../../includes/useHttpHook";
interface SubscriptionModalProp {
    onClose:()=>void;
    selectedPlan:PlansProp;
    onSubscribe:(paymentUrl:string)=>void;
}
const SubscriptionModal = (props:SubscriptionModalProp)=>{
    const [total,setTotal] = useState<number>(0);
    const [count,setCount] = useState<number>(1);
    const {handleGeneratePaymentUrl,loading} = useHttpHook();

    useEffect(()=>{
        setTotal(parseFloat(props.selectedPlan.price))  
    },[props.selectedPlan])
return <div className="modal"  tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
<div className="modal-dialog">
  <div className="modal-content">
    <div className="modal-header">
      <h5 className="modal-title" id="exampleModalLabel">Subscribe</h5>
      <button onClick={()=>props.onClose()} type="button" className="btn-close" ></button>
    </div>
    <div className="modal-body px-5">
     <div className="fw-bold">{props.selectedPlan.title}</div>
     <ul >
      {String(props.selectedPlan.description).split("|").map((b,o)=><li className="bullet" key={o}>{b}</li>)}
     </ul>
    <div className="fs-5 mt-3 fw-bolder">NGN{ReturnComma(props.selectedPlan.price)} / Per Month</div>
    <div className="px-5">
    <div className="input-group m-3">
  <span className="input-group-text fs-4 cursor"
  onClick={()=>{
    if(count > 1)
    {
        setCount(count - 1)
    }
  }}
  >-</span>
  <span className="form-control text-center fs-4" >{count} Month{count > 1?"s":""}</span>
  <span className="input-group-text fs-4 cursor"
   onClick={()=>{
    if(count < 12)
    {
        setCount(count + 1)
    }
  }}
  >+</span>
</div>
    </div>
    </div>
    <div className="modal-footer">
      <BaseButton 
      loading={loading}
      onClick={()=>{
        handleGeneratePaymentUrl(props.selectedPlan.id,count).then((res)=>{
            if(res.status)
            {
                props.onSubscribe(res.data.paymentUrl)}
            })
    }}
      >Pay NGN {ReturnComma(String(total*count))}</BaseButton>
    </div>
  </div>
</div>
</div>
}
export default SubscriptionModal;