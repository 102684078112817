import React, { useState } from 'react'
import { Logo } from '../../components/Logo'
import { LogoDesign } from '../../components/LogoDesign'
import { PoweredByComponent } from '../../components/PoweredBy'
import BaseInput from '../../components/baseInput'
import { NavLink, useNavigate } from 'react-router-dom'
import { CONSTANTS } from '../../includes/constant'
import { BaseButton } from '../../components/buttons'
import { Formik} from 'formik';
import * as y from 'yup';
import { PostRequest } from '../../includes/functions';

const schema = y.object({
    firstName:y.string().required(),
    lastName:y.string().required(),
    password:y.string().required(),
    email:y.string().required().email("A valid email is required."),
    phoneNumber:y.string().required()
    })
  export default function RegisterScreen() {
    const [loading,setLoading] = useState(false);
    const navigate = useNavigate();
    const UserRegistration = (values:any)=>{
      setLoading(true);
      PostRequest(CONSTANTS.Routes.CreateAccount,values,true,true,"upload").then((response)=>{
        setLoading(false);
        if(response.status)
        {
         navigate("/"+CONSTANTS.Routes.Login,{replace:true});
        }
      })
    }
    return (<div className='container text-center p-5'>
      <div >
        <img 
        className='logo-container'
        src='https://awabahng.com/static/media/awabahLogo.0e8b9750.svg'
        />
      </div>
  <Formik
  onSubmit={(values)=>{
    UserRegistration(values)
  }}
  validationSchema={schema}
  initialValues={{
    firstName:"",
    lastName:"",
    email:"",
    password:"",
    phoneNumber:""
  }}
  >
  {({handleSubmit,handleChange,values})=><div className='text-center p-5' >
          <div className="title-text">Welcome to ValuBah!
          😊</div>
          <div className="">Sign up with your valid details.</div>
          <div className='row my-5' >
          <div className='col-lg-2 d-none d-sm-block' ></div>
          <div className='col-lg-8 col-sm-12 col-md-12' >
          <BaseInput 
          name='firstname'
          type='text'
          placeholder='Enter your first name.'
          max={50}
          onValueChange={handleChange("firstName")}  
           value={values.firstName}
          required={true}
          />
          
          <BaseInput 
          name='lastname'
          type='text'
          placeholder='Enter your last name'
          max={50}
          onValueChange={handleChange("lastName")}  
           value={values.lastName}
          required={true}
          />
           <BaseInput 
          name='phoneNumber'
          type='mobile'
          placeholder='Enter your phone number'
          max={15}
          onValueChange={handleChange("phoneNumber")} 
          value={values.phoneNumber}
          required={true}
          />
           <BaseInput 
          name='password'
          type='password'
          placeholder='Password'
          max={50}
          onValueChange={handleChange("password")} 
          value={values.password}
          required={true}
          />
          <BaseInput 
          name='email'
          type='email'
          placeholder='Enter your email'
          max={100}
          onValueChange={handleChange("email")}  
          value={values.email}
          required={true}
          />
          <div className='row'>
          <div className='col-12 mb-5'>
          </div>
          </div>
          <div className='row p-2 pe-3 mb-3' >
          <BaseButton 
          onClick={handleSubmit}
          loading={loading}
          >Register</BaseButton>
          </div>
          <NavLink to={"../"+CONSTANTS.Routes.Login} 
          className={"blackText"}>
              <span >I have account? <b >Login</b></span>
          </NavLink>
          </div>
          <div className='col-lg-2 d-none d-sm-block' ></div>
          </div>
          </div>}
       </Formik>
       </div>
    )
  }