import React, { useState } from 'react'
import { Logo } from '../../components/Logo'
import { LogoDesign } from '../../components/LogoDesign'
import { PoweredByComponent } from '../../components/PoweredBy'
import BaseInput from '../../components/baseInput'
import { Navigate, NavLink, useNavigate } from 'react-router-dom'
import { CONSTANTS } from '../../includes/constant'
import { BaseButton } from '../../components/buttons'
import { Formik} from 'formik';
import * as y from 'yup';
import { PostRequest } from '../../includes/functions';

const schema = y.object({
    email:y.string().required().email("A valid email is required."),
    password:y.string().required()
    })
export default function LoginScreen() {
  const [loading,setLoading] = useState(false);
  const navigate = useNavigate()
  const UserLogin = (values:any)=>{
    setLoading(true);
    PostRequest(CONSTANTS.Routes.Login,values,true,true,"upload").then((response)=>{
      setLoading(false);
      if(response.status)
      {
       navigate("/"+CONSTANTS.Routes.Dashboard);
      }
    })
  }
  if(localStorage.getItem(CONSTANTS.Routes.Login))
    {
     return <Navigate to={"/"+CONSTANTS.Routes.Dashboard} />
    }
  return (<div className='container text-center p-5'>
   <div >
    <img 
    className='logo-container'
    src='https://awabahng.com/static/media/awabahLogo.0e8b9750.svg'
    />
  </div>
     <Formik
onSubmit={(values)=>{
UserLogin(values)
}}
validationSchema={schema}
initialValues={{
  email:"",
  password:""
}}
>
{({handleSubmit,handleChange,values})=><div className='text-center p-5' >
        <div className="title-text">Welcome to ValuBah!
        😊</div>
        <div className="">Provide a valid Awabah email and password!</div>
        <div className='row my-5' >
        <div className='col-lg-2 d-none d-sm-block' ></div>
        <div className='col-lg-8 col-sm-12 col-md-12' >
        <BaseInput 
        name='email'
        type='email'
        placeholder='Work email address'
        max={100}
        onValueChange={handleChange("email")}  
         value={values.email}
        required={true}
        />
         <BaseInput 
        name='password'
        type='password'
        placeholder='Password'
        max={50}
        onValueChange={handleChange("password")} 
         value={values.password}
        required={true}
        />
        <div className='row'>
        <div className='col-12 mb-5'>
        <NavLink to={"../"+CONSTANTS.Routes.ForgotPassword} className={"recovery-text"}>
            <span >Forgot password? <b>Recover</b></span>
        </NavLink>
        </div>
        </div>
        <div className='row p-2 pe-3 mb-3' >
        <BaseButton 
        onClick={handleSubmit}
        loading={loading}
        >Login</BaseButton>
        </div>
        <NavLink to={"../"+CONSTANTS.Routes.CreateAccount} 
        className={"blackText"}>
            <span >I don't have account? <b >Sign Up</b></span>
        </NavLink>
        </div>
        <div className='col-lg-2 d-none d-sm-block' ></div>
        </div>
        </div>}
     </Formik>
     </div>
  )
}