import { useEffect, useState } from "react";
import { PostRequest,GetRequest, ReturnComma } from "../../includes/functions";
import useHttpHook from "../../includes/useHttpHook";
import moment from "moment";
import { BaseLoader } from "../../components/baseloader";
import { BaseButton } from "../../components/buttons";
import SubscriptionModal from "../../components/planSubscriptionModal";
import PaymentModal from "../payment";
interface SubscriptionHistoryProp {
amount:string;
memo:string;
id:string;
email:string;
ref:string;
status:string;
createdAt:string;
title:string;
planId:string;
txtRef:string;
txtId:string;
months:string;
}
export interface PlansProp {
id:string;
title:string;
description:string;
price:string;
createdAt:string;
}
const SubscriptionHistoryScreen = ()=>{
    const [list,setList] = useState<SubscriptionHistoryProp[]>([]);
    const [plans,setPlans] = useState<PlansProp[]>([]);
    const [selectedPlans,setSelectedPlans] = useState<PlansProp | null>(null);
    const [selectedSavePlans,setSelectedSavePlans] = useState<PlansProp | null>(null);
    const [paymentUrl,setPaymentUrl] = useState<string>("");
    const {handleGetSubscriptions,handleGetSubscriptioPlans,loading} = useHttpHook();
    const GetHistory = ()=>{
      handleGetSubscriptions().then((res)=>{
            if(res.status)
            {
                setList(res.data.subscriptions);
            }
        })
    }
    const HandlePayment = (paymentUrl:string)=>{
      setSelectedSavePlans(selectedPlans);
      setPaymentUrl(paymentUrl)
      setSelectedPlans(null)
    }
    const GetPlans = ()=>{
      handleGetSubscriptioPlans().then((res)=>{
            if(res.status)
            {
                setPlans(res.data);
            }
        })
    }
    useEffect(()=>{
      GetPlans();
      GetHistory();

    },[])
return <div >
  <div className="planCard-container">
    {plans.map((a,i)=>{
      var li = String(a.description).split("|").map((b,o)=><li className="bullet" key={o}>{b}</li>);
      return <div className={`card bg-light m-3`} style={{maxWidth:"18rem",minWidth:"16rem"}}>
  <div className="card-body">
  <div className="fw-bold fs-30">{a.title}</div>
    <ul className="fs-13 mt-2">{li}</ul>
    <div className="fs-40 mt-3">NGN{ReturnComma(a.price)} / Per Month</div>
  </div>
  <div className="card-footer bg-transparent">
    <div className="row">
  <div className="col-14">
  <BaseButton 
       onClick={()=>{
        setSelectedPlans(a);
       }}
       right
      >
        Buy NGN{ReturnComma(a.price)}
  </BaseButton>
  </div>
  </div>
  </div>
</div>})}
  </div>
    <table className="table">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Plan Name</th>
      <th scope="col">Plan Description</th>
      <th scope="col">Ref No.</th>
      <th scope="col">Amount</th>
      <th scope="col">Months</th>
      <th scope="col">Date</th>
    </tr>
  </thead>
  <tbody>
{loading &&<tr >
    <td colSpan={6}>
        <BaseLoader  /> loading ...
    </td>
    </tr>}
    {list.map((a,i)=><tr key={i}>
      <th scope="row">{i+1}</th>
      <td>{a.title}</td>
      <td>{a.memo}</td>
      <td>{a.txtRef}</td>
      <td>NGN{ReturnComma(a.amount)}</td>
      <td>{ReturnComma(a.months)}</td>
      <td>{moment(a.createdAt).format("Do, MMM YYYY")}</td>
    </tr>)}
  </tbody>
</table>
{selectedPlans && <SubscriptionModal 
onClose={()=>setSelectedPlans(null)}
selectedPlan={selectedPlans}
onSubscribe={(paymentUrl)=>{
  HandlePayment(paymentUrl)
}}
/>}
{selectedSavePlans && <PaymentModal 
onClose={()=>{
  GetHistory();
  setSelectedSavePlans(null);
}}
paymentUrl={paymentUrl}

/>}
</div>
}
export default SubscriptionHistoryScreen;