import React, { useState } from 'react'
import BaseInput from '../../components/baseInput'
import { NavLink, useNavigate} from 'react-router-dom'
import { CONSTANTS } from '../../includes/constant'
import { BaseButton } from '../../components/buttons'
import { Formik } from 'formik';
import * as y from 'yup';
import { PostRequest } from '../../includes/functions'

const schema = y.object({
  email:y.string().required().email("A valid email is required."),
  })
export default function ForgotPasswordScreen() {
const [loading,setLoading] = useState(false);
const navigate = useNavigate()
const UserLogin = (values:any)=>{
  setLoading(true);
  PostRequest(CONSTANTS.Routes.Login,values,true,true).then((response)=>{
    setLoading(false);
    if(response.status)
    {
     navigate("/"+CONSTANTS.Routes.Dashboard);
    }
  })
}
return (<div className='container text-center p-5'>
  <div >
    <img 
    className='logo-container'
    src='https://awabahng.com/static/media/awabahLogo.0e8b9750.svg'
    />
  </div>
   <Formik
onSubmit={(values)=>{
UserLogin(values)
}}
validationSchema={schema}
initialValues={{
email:""
}}
>
{({handleSubmit,handleChange,values})=><div className='text-center p-5' >
     <div className="title-text">Forgot password?</div>
      <div className="">Enter your email address and we'll send you a link to reset your password.</div>
      <div className='row my-5' >
      <div className='col-lg-2 d-none d-sm-block' ></div>
      <div className='col-lg-8 col-sm-12 col-md-12' >
      <BaseInput 
      name='email'
      type='email'
      placeholder='Enter your email address'
      max={100}
      onValueChange={handleChange("email")}  
       value={values.email}
      required={true}
      />
      
      <div className='row p-2 pe-3 mb-3' >
      <BaseButton 
      onClick={handleSubmit}
      loading={loading}
      >Continue</BaseButton>
      </div>
      <NavLink to={"../"+CONSTANTS.Routes.Login} 
      className={"blackText"}>
          <span >Go back to <b >Login</b></span>
      </NavLink>
      </div>
      <div className='col-lg-2 d-none d-sm-block' ></div>
      </div>
      </div>}
   </Formik>
   </div>
)
}