import { useState } from "react";
import { APIResponse, GetRequest, PostRequest } from "./functions";

const useHttpHook = ()=>{
    const [loading,setLoading] = useState<boolean>(false);
    const handleGetTransactions = ()=>{
       return new Promise<APIResponse>((resolve)=>{
        setLoading(true);
        GetRequest("transactions",{}).then((res)=>{
        setLoading(false);
        resolve(res);
        })
    })
    }
    const handleConfirmPayment= (ref:string,paymentUrl:string)=>{
        return new Promise<APIResponse>((resolve)=>{
         setLoading(true);
         PostRequest("confirm_payment",{reference:ref,paymentUrl},true,true,"upload").then((res)=>{
         setLoading(false);
         resolve(res);
         })
     })
     }
    const handleGetSubscriptioPlans = ()=>{
        return new Promise<APIResponse>((resolve)=>{
         setLoading(true);
         GetRequest("plans",{}).then((res)=>{
         setLoading(false);
         resolve(res);
         })
     })
     }
    const handleGetSubscriptions = ()=>{
        return new Promise<APIResponse>((resolve)=>{
         setLoading(true);
         GetRequest("subscriptions",{}).then((res)=>{
         setLoading(false);
         resolve(res);
         })
     })
     }
     const handleGeneratePaymentUrl  = (id:string,months:number)=>{
        return new Promise<APIResponse>((resolve)=>{
         setLoading(true);
         PostRequest("subscribe",{planId:id,months:months},false,false,"upload").then((res)=>{
         setLoading(false);
         resolve(res);
         })
     })
     }
return {
    loading,
    handleGetTransactions,
    handleGetSubscriptions,
    handleGetSubscriptioPlans,
    handleGeneratePaymentUrl,
    handleConfirmPayment
}
}
export default useHttpHook;