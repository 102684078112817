import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { CONSTANTS } from './includes/constant';
import LoginScreen from './screens/login';
import ForgotPasswordScreen from './screens/forget_password';
import OTPScreen from './screens/otp';
import DashboardScreen from './screens/dashboard';
import RegisterScreen from './screens/createAccount';
import TransactionHistoryScreen from './screens/history';
import ProfileScreen from './screens/profile';
import SubscriptionScreen from './screens/subscriptions';
import HomeScreen from "./screens/home";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CreatePasswordScreen from './screens/create_password';
function App() {
  return (<BrowserRouter>
    <Routes>
    <Route 
    path={CONSTANTS.Routes.Dashboard} element={<DashboardScreen />}
    errorElement={<HomeScreen />}
    >
    <Route path={CONSTANTS.Routes.History} element={<TransactionHistoryScreen />} />
    <Route path={CONSTANTS.Routes.Profile} element={<ProfileScreen />} />
    <Route path={CONSTANTS.Routes.Subscriptions} element={<SubscriptionScreen />} />
    <Route path={""} element={<HomeScreen />}  />
    <Route path={"*"} element={<HomeScreen />} />
    </Route>
    <Route path={CONSTANTS.Routes.Login} element={<LoginScreen />} />
    <Route path={CONSTANTS.Routes.CreateAccount} element={<RegisterScreen />} />
    <Route path={CONSTANTS.Routes.ForgotPassword} element={<ForgotPasswordScreen />} />
    {localStorage.getItem(CONSTANTS.Routes.ForgotPassword) && <>
    <Route path={CONSTANTS.Routes.Otp} element={<OTPScreen />} />
    </>}
    <Route path={"*"} element={<LoginScreen />} />
    </Routes>
    <ToastContainer />
    </BrowserRouter>);
}
   
export default App;
