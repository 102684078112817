export const CONSTANTS = {
    BaseURL:"https://awabah.com/api/v1/",//"http://localhost/awabah-api/",
    Routes:{
      Login:"login",
      Otp:"otp",
      ForgotPassword:"forgot_password",
      Dashboard:"dashboard",
      Home:"dashboard/",
      History:"transactions",
      CreatePassword:"create_password",
      Settings:"settings",
      Subscriptions:"subscriptions",
      Profile:"profile",
      CreateAccount:"register"
    },
    Events:{
      reloadBusiness:"reloadBusiness",
      reloadAllBusiness:"reloadAllBusiness",
      reloadVehicles:"reloadVehicles"
    }
}

interface ListProps {
icon?:JSX.Element;
title:string;
link:string;
}

export const DashboardNavItems:ListProps[] = [
  {title:"Dashboard",link:"/"+CONSTANTS.Routes.Dashboard},
  {title:"Transactions",link:"/"+CONSTANTS.Routes.Dashboard+"/"+CONSTANTS.Routes.History},
  {title:"Subscriptions",link:"/"+CONSTANTS.Routes.Dashboard+"/"+CONSTANTS.Routes.Subscriptions},
  {title:"Profile",link:"/"+CONSTANTS.Routes.Dashboard+"/"+CONSTANTS.Routes.Profile},
] 
