import { useEffect, useState } from "react";
import { PostRequest,GetRequest, ReturnComma } from "../../includes/functions";
import useHttpHook from "../../includes/useHttpHook";
import moment from "moment";
import { BaseLoader } from "../../components/baseloader";
interface TransactionHistoryProp {
amount:string;
memo:string;
id:string;
email:string;
ref:string;
status:string;
createdAt:string;
paymentUrl:string;
}
const TransactionHistoryScreen = ()=>{
    const [list,setList] = useState<TransactionHistoryProp[]>([]);
    const {handleGetTransactions,loading} = useHttpHook();
    const GetHistory = ()=>{
        handleGetTransactions().then((res)=>{
            if(res.status)
            {
                setList(res.data.transactions);
            }
        })
    }
    useEffect(()=>{
        GetHistory();
    },[])
return <div >
    <table className="table">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Description</th>
      <th scope="col">Ref No.</th>
      <th scope="col">Amount</th>
      <th scope="col">Status</th>
      <th scope="col">Date</th>
    </tr>
  </thead>
  <tbody>
{loading &&<tr >
    <td colSpan={6}>
        <BaseLoader  /> loading ...
    </td>
    </tr>}
    {list.map((a,i)=><tr key={i}>
      <th scope="row">{i+1}</th>
      <td>{a.memo}</td>
      <td>{a.ref}</td>
      <td>NGN{ReturnComma(a.amount)}</td>
      <td><div className={a.status} >{a.status}</div></td>
      <td>{moment(a.createdAt).format("Do, MMM YYYY")}</td>
    </tr>)}
  </tbody>
</table>
</div>
}
export default TransactionHistoryScreen;